import {
  Alert,
  Col,
  PageRequest,
  PagedResponse,
  Panel,
  Row,
  SearchPagination,
  SectionTitle,
  Sort,
  usePagedQuery,
  useShowNotification
} from '@elotech/components';
import { AxiosPromise } from 'axios';
import React, { useCallback, useEffect } from 'react';

import { Usuario } from '../../type';
import { BasicSearchFilter } from '../index';
import UsuarioSelectorTable from './UsuarioSelectorTable';

type Props = {
  findDisponiveis: (
    search: string,
    pagination?: PageRequest,
    sort?: Sort
  ) => AxiosPromise<PagedResponse<Usuario>>;
  findVinculados: (
    search: string,
    pagination?: PageRequest,
    sort?: Sort
  ) => AxiosPromise<PagedResponse<Usuario>>;
  onAdd: (grupo: Usuario) => AxiosPromise<unknown>;
  onRemove: (grupo: Usuario) => AxiosPromise<unknown>;
};

const UsuarioSelector: React.FC<Props> = ({
  findDisponiveis,
  findVinculados,
  onAdd,
  onRemove
}) => {
  const showNotification = useShowNotification();

  const {
    loading: loadingDisponiveis,
    values: valuesDisponiveis,
    pagination: paginationDisponiveis,
    doSearch: doSearchDisponiveis,
    doPagedSearch: doPagedSearchDisponiveis
  } = usePagedQuery<Usuario>({
    search: findDisponiveis,
    onError: useCallback(
      error =>
        Alert.error(
          { title: 'Erro ao buscar os usuários disponíveis.' },
          error
        ),
      []
    ),
    disableQueryPagination: true
  });
  const {
    loading: loadingVinculados,
    values: valuesVinculados,
    pagination: paginationVinculados,
    doSearch: doSearchVinculados,
    doPagedSearch: doPagedSearchVinculados
  } = usePagedQuery<Usuario>({
    search: findVinculados,
    onError: useCallback(
      error =>
        Alert.error({ title: 'Erro ao buscar os usuários Vinculados.' }, error),
      []
    ),
    disableQueryPagination: true
  });

  const searchBoth = useCallback(() => {
    doSearchVinculados();
    doSearchDisponiveis();
  }, []);

  useEffect(() => {
    searchBoth();
  }, [searchBoth]);

  const onAddUsuario = (usuario: Usuario) => {
    onAdd(usuario)
      .then(() => {
        showNotification({
          level: 'success',
          message: `Usuário ${usuario.nome} adicionado com sucesso`
        });
        searchBoth();
      })
      .catch((error: any) => {
        Alert.error(
          { title: `Erro ao adicionar o usuário ${usuario.nome}.` },
          error
        );
      });
  };

  const onRemoveUsuario = (usuario: Usuario) => {
    onRemove(usuario)
      .then(() => {
        showNotification({
          level: 'success',
          message: `Usuário ${usuario.nome} removido com sucesso`
        });
        searchBoth();
      })
      .catch((error: any) => {
        Alert.error(
          { title: `Erro ao remover o usuário ${usuario.nome}.` },
          error
        );
      });
  };

  return (
    <Row>
      <Col md={6}>
        <SectionTitle marginTop="0px">Usuários disponíveis</SectionTitle>
        <Panel isTable>
          <BasicSearchFilter
            onSearch={doSearchDisponiveis}
            disableInitialSearch
          />
          <UsuarioSelectorTable
            emptyMessage={'Nenhum usuário disponível'}
            loading={loadingDisponiveis}
            usuarios={valuesDisponiveis}
            icon={'plus'}
            label={'Adicionar'}
            onAction={onAddUsuario}
            readOnly={false}
          />
          {paginationDisponiveis && (
            <SearchPagination
              page={paginationDisponiveis}
              searchWithPage={doPagedSearchDisponiveis}
              splitIntoTwoRows
            />
          )}
        </Panel>
      </Col>
      <Col md={6}>
        <SectionTitle marginTop="0px">Usuários Vinculados</SectionTitle>
        <Panel isTable>
          <BasicSearchFilter
            onSearch={doSearchVinculados}
            disableInitialSearch
          />
          <UsuarioSelectorTable
            emptyMessage={'Nenhum usuário vinculado'}
            loading={loadingVinculados}
            usuarios={valuesVinculados}
            icon={'trash'}
            label={'Remover'}
            onAction={onRemoveUsuario}
            readOnly={false}
          />
          {paginationVinculados && (
            <SearchPagination
              page={paginationVinculados}
              searchWithPage={doPagedSearchVinculados}
              splitIntoTwoRows
            />
          )}
        </Panel>
      </Col>
    </Row>
  );
};

export default UsuarioSelector;
